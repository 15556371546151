import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  Fragment,
} from "react";
import { Input, Divider } from "antd";
import Cron from "react-js-cron";

import { InfoCircleOutlined } from "@ant-design/icons";

import "antd/dist/antd.min.css";
import { isCronValid, isEmpty } from "../../utils/utils";
import { Row, Form, Col } from "react-bootstrap";
import {
  Mobile,
  Tablet,
} from "../../Componenets/react-responsive/responsiveDevices";
import { Add, Minus } from "../../Componenets/Icons/Icons";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";

const forecastTypes = [
  { value: "infer_forecast", label: "Infer Forecast" },
  { value: "learn_forecast", label: "Learn Forecast" },
  { value: "feature_store", label: "Feature Store" },
  { value: "Run Forecast", label: "Run Forecast" },
];

const animatedComponents = makeAnimated();

const getVal = (forecastingType) =>
  forecastTypes.filter((item) => item.value === forecastingType);

const CronForm = ({
  setCronVal,
  currentCronVal,
  type,
  setParams,
  setForecastingType,
  params,
  forecastingType,
  kwrags,
  setKwargs,
}) => {
  const inputRef = useRef(null);

  // for param fields
  const [todayDate] = useState(new Date());
  const [forecastType, setForecastType] = useState(getVal(forecastingType));
  const [command, setCommand] = useState(`--date ${todayDate}`);
  const [inputFields, setInputFields] = useState(params);

  const [kwargsCommand, setKwargsCommand] = useState(``);
  const [kwargsFields, setKwargsFields] = useState(kwrags);

  useEffect(() => {
    let command = ``;

    if (type === "forecast" && forecastType && forecastType.value) {
      command += ` --forecast_type ${forecastType.value}`;
    }

    inputFields.forEach((item) => {
      if (!isEmpty(item.fieldName) && !isEmpty(item.fieldValue)) {
        const fieldName = item.fieldName.replace(/-/g, "_");
        command += ` --${fieldName} ${item.fieldValue} `;
      }
    });
    kwargsFields.forEach((item) => {
      if (!isEmpty(item.kwargName) && !isEmpty(item.kwargValue)) {
        const fieldName = item.kwargName.replace(/-/g, "_");
        command += ` --${item.kwargName} ${item.kwargValue} `;
      }
    });
    setCommand(command);
  }, [todayDate, inputFields, forecastType, type, kwargsFields]);

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({ fieldName: "", fieldValue: "" });
    setInputFields(values);
    setParams(values);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
    setParams(values);
  };

  const handleKwargsAddFields = () => {
    const kwargValues = [...kwargsFields];
    kwargValues.push({ kwargName: "", kwargValue: "" });
    setKwargsFields(kwargValues);
    setKwargs(kwargValues);
  };

  const handleKwargsRemoveFields = (index) => {
    const values = [...kwargsFields];
    values.splice(index, 1);
    console.log(values, "vallll");
    setKwargsFields(values);
    setKwargs(values);
  };

  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    if (event.target.name === "fieldName") {
      values[index].fieldName = event.target.value;
    } else {
      values[index].fieldValue = event.target.value;
    }
    setInputFields(values);
    setParams(values);
  };

  const handleKwargsInput = (index, event) => {
    const values = [...kwargsFields];
    if (event.target.name === "kwargName") {
      values[index].kwargName = event.target.value;
    } else {
      values[index].kwargValue = event.target.value;
    }
    setKwargsFields(values);
    setKwargs(values);
  };

  const handleForecast = (val) => {
    setForecastType(val);
    setForecastingType(val.value);
    // setSelectedFreq(val);
  };

  const [value, setValue] = useState(
    currentCronVal ? currentCronVal : "* * * * *"
  );

  const customSetValue = useCallback(
    (newValue) => {
      setValue(newValue);
      if (!isCronValid(newValue)) {
        setError("Please enter valid cron");
      }
      // inputRef.current && inputRef.current.setValue(newValue);
      if (inputRef.current) {
        inputRef.current.value = newValue;
      }
    },
    [inputRef]
  );

  useEffect(() => {
    setCronVal(value);
    if (value && !isCronValid(value)) {
      setError("Please enter valid cron");
    } else if (value === "* * * * *") {
      setError("This cron value is not allowed");
    } else {
      setError("");
    }
  }, [setCronVal, value]);

  const [error, setError] = useState();

  const onError = () => {
    console.log(error);
  };

  return (
    <div style={{ padding: 20 }}>
      <Input
        ref={inputRef}
        value={value}
        onBlur={(event) => {
          setValue(event.target.value);
        }}
        onChange={(event) => {
          setValue(event.target.value);
        }}
        onPressEnter={() => {
          setValue(inputRef.current?.input.value || "");
        }}
      />

      <Divider>OR</Divider>

      <Cron
        value={value}
        setValue={customSetValue}
        setError={onError}
        leadingZero="always"
      />

      <div>
        <InfoCircleOutlined style={{ marginRight: 5 }} />
        <span style={{ fontSize: 12 }}>
          Double click on a dropdown option to automatically select / unselect a
          periodicity
        </span>
      </div>

      <Divider></Divider>

      <div className="mt-4">
        {type === "forecast" && (
          <Row className="mb-3">
            <Form.Group as={Col} controlId="forecast">
              <Form.Label>Forecast Type</Form.Label>
              <AsyncSelect
                defaultOptions={forecastTypes}
                value={forecastType}
                components={animatedComponents}
                inputId="frequency"
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999999999,
                    position: "inherit",

                    border: "1px solid #ced4da",
                    boxShadow: "none",
                  }),
                }}
                onChange={handleForecast}
              />
            </Form.Group>
          </Row>
        )}

        <h5 className="mt-4">Config Filters</h5>

        {inputFields.map((inputField, index) => (
          <Fragment key={`${inputField}~${index}`}>
            <Row>
              <Form.Group
                as={Col}
                xs={12}
                md={6}
                controlId="field-name"
                className="mb-3"
              >
                <Form.Label>Param Name</Form.Label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  name="fieldName"
                  value={inputField.fieldName}
                  onChange={(event) => handleInputChange(index, event)}
                />
              </Form.Group>
              <Form.Group
                as={Col}
                xs={12}
                md={6}
                controlId="field-value"
                className="mb-3"
              >
                <Form.Label>Param Value</Form.Label>
                <div style={{ display: "flex" }}>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    name="fieldValue"
                    value={inputField.fieldValue}
                    onChange={(event) => handleInputChange(index, event)}
                  />
                  <Tablet>
                    <span style={{ display: "flex" }}>
                      <button
                        className="btn btn-link"
                        type="button"
                        onClick={() => handleAddFields()}
                        style={{ padding: "4px" }}
                      >
                        <Add />
                      </button>
                      {index !== 0 && (
                        <button
                          className="btn btn-link"
                          type="button"
                          disabled={index === 0}
                          onClick={() => handleRemoveFields(index)}
                          style={{ padding: "4px" }}
                        >
                          <Minus />
                        </button>
                      )}
                    </span>
                  </Tablet>
                </div>
              </Form.Group>
              <Mobile>
                <span style={{ textAlign: "center" }}>
                  <button
                    className="btn btn-link"
                    type="button"
                    onClick={() => handleAddFields()}
                    style={{ padding: "4px" }}
                  >
                    <Add />
                  </button>
                  {index !== 0 && (
                    <button
                      className="btn btn-link"
                      type="button"
                      disabled={index === 0}
                      onClick={() => handleRemoveFields(index)}
                      style={{ padding: "4px" }}
                    >
                      <Minus />
                    </button>
                  )}
                </span>
              </Mobile>
            </Row>
          </Fragment>
        ))}

        <h5 className="mt-2">Kwargs Fields</h5>

        {kwargsFields.map((inputField, index) => (
          <Fragment key={`${inputField}~${index}`}>
            <Row>
              <Form.Group
                as={Col}
                xs={12}
                md={6}
                controlId="field-name"
                className="mb-3"
              >
                <Form.Label>Param Name</Form.Label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  name="kwargName"
                  value={inputField.kwargName}
                  onChange={(event) => handleKwargsInput(index, event)}
                />
              </Form.Group>
              <Form.Group
                as={Col}
                xs={12}
                md={6}
                controlId="field-value"
                className="mb-3"
              >
                <Form.Label>Param Value</Form.Label>
                <div style={{ display: "flex" }}>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    name="kwargValue"
                    value={inputField.kwargValue}
                    onChange={(event) => handleKwargsInput(index, event)}
                  />
                  <Tablet>
                    <span style={{ display: "flex" }}>
                      <button
                        className="btn btn-link"
                        type="button"
                        onClick={() => handleKwargsAddFields()}
                        style={{ padding: "4px" }}
                      >
                        <Add />
                      </button>
                      {index !== 0 && (
                        <button
                          className="btn btn-link"
                          type="button"
                          disabled={index === 0}
                          onClick={() => handleKwargsRemoveFields(index)}
                          style={{ padding: "4px" }}
                        >
                          <Minus />
                        </button>
                      )}
                    </span>
                  </Tablet>
                </div>
              </Form.Group>
              <Mobile>
                <span style={{ textAlign: "center" }}>
                  <button
                    className="btn btn-link"
                    type="button"
                    onClick={() => handleKwargsAddFields()}
                    style={{ padding: "4px" }}
                  >
                    <Add />
                  </button>
                  {index !== 0 && (
                    <button
                      className="btn btn-link"
                      type="button"
                      disabled={index === 0}
                      onClick={() => handleKwargsRemoveFields(index)}
                      style={{ padding: "4px" }}
                    >
                      <Minus />
                    </button>
                  )}
                </span>
              </Mobile>
            </Row>
          </Fragment>
        ))}
      </div>

      <Row className="">
        <Form.Group as={Col} className="" controlId="command">
          <strong>
            {" "}
            <Form.Label>Genrated Command</Form.Label>
          </strong>
          <Form.Control
            type="text"
            placeholder="Enter your command directly"
            value={command}
            onChange={(e) => setCommand(e.target.value)}
            as="textarea"
            disabled
          />
        </Form.Group>
      </Row>

      {/* <p style={{ marginTop: 20 }}> {error ? "Error: " + error : ""}</p> */}
    </div>
  );
};

export default CronForm;
