import React, { useCallback, useEffect, useRef, useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import { IoMdAddCircle } from "react-icons/io";
import Swal from "sweetalert2";
import Loader from "../../Componenets/Loader/Loader";
import PageTitle from "../../Componenets/PageTitle/PageTitle";
import SearchFilter from "../../Componenets/SearchFilter/SearchFilter";
import TableButton from "../../Componenets/TableButtons/TableButton";
import styles from "./configmanager.module.css";
import cron from "cron-validate";

import withReactContent from "sweetalert2-react-content";

import { Provider, useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import {
  Add,
  ConfigPlay,
  CronIcon,
  Delete,
  DownloadConfig,
  EditConfig,
  Pause,
  Play,
} from "../../Componenets/Icons/Icons";
// import Pagination from "../../Componenets/Pagination/Pagination";
import PostPerPage from "../../Componenets/PostPerPage/PostPerPage";
import { SweetAlerts } from "../../Componenets/SweetAlerts/SweetAlerts";
import { store } from "../../app/store";

import {
  useGetConfigsQuery,
  useLazyDownloadConfigQuery,
  useLazyGetConfigsByUidQuery,
  useRunConfigMutation,
  useSuspendConfigMutation,
} from "../../service/configService";
import {
  addParams,
  checkExecAccess,
  convert,
  filterByVal,
  isEmpty,
} from "../../utils/utils";
import TriggerConfigForm from "./TriggerConfigForm";
import {
  Desktop,
  Mobile,
  Tablet,
} from "../../Componenets/react-responsive/responsiveDevices";

import { WarningSweetAlerts } from "../../Componenets/SweetAlerts/WarningSweetAlerts";
import { saveActiveConfigVal, saveConfigTxt } from "../../service/filterSlice";
import { Switch } from "pretty-checkbox-react";
import ReactTable from "../../Componenets/ReactTable/ReactTable";
import {
  useAddCronMutation,
  useLazyGetCronsQuery,
  useUpdateCronMutation,
} from "../../service/cronService";
import cronstrue from "cronstrue";
import CronForm from "../ConfigEditor/CronForm";

const MySwal = withReactContent(Swal);

function isValidJSON(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

const ConfigManager = () => {
  const dispatch = useDispatch();
  const cronValRef = useRef(null);

  const [setCurrentPage] = useState(1);
  const [postsPerPage, setPostPerPage] = useState(20);
  const [searchParams] = useSearchParams();
  const [cronVal, setCronVal] = useState("* * * * *");
  cronValRef.current = cronVal;

  const srchTxt = searchParams.get("searchText");
  const activeConfigVal = searchParams.get("activeconfig");

  const { configSearchText, activeConfig } = useSelector(
    (state) => state.filterSlice
  );

  const [checked, setChecked] = useState(
    activeConfigVal === "true"
      ? true
      : activeConfigVal === "false"
      ? false
      : activeConfig
  );

  const [searchText, setSearchText] = useState(
    srchTxt ? srchTxt : configSearchText
  );

  const [filteredPosts, setFilteredPosts] = useState([]);
  const [frequency, setFrequency] = useState("date");

  const [forecastingType, setForecastingType] = useState();
  const [forecastingTypeVal, setForecastingTypeVal] = useState();

  const [params, setParams] = useState([{ fieldName: "", fieldValue: "" }]);
  const [kwargs, setKwargs] = useState([{ kwargName: "", kwargValue: "" }]);

  const { userAccess } = useSelector((state) => state);

  const forecastingTypeRef = useRef();
  forecastingTypeRef.current = forecastingType;

  const forecastingTypeValRef = useRef();
  forecastingTypeValRef.current = forecastingTypeVal;

  const frequencyRef = useRef();
  frequencyRef.current = frequency;

  const paramsRef = useRef();
  paramsRef.current = params;

  const region = useSelector((state) => state.region);

  const getSelectedFrequency = () => frequencyRef.current;
  const getSelectedForecastType = () => forecastingTypeRef.current;
  // const getSelectedForecastTypeVal = () => forecastingTypeValRef.current;

  const kwargsRef = useRef();
  kwargsRef.current = kwargs;

  const getKwargs = () => kwargsRef.current;

  const getParmas = () => paramsRef.current;

  const { data, error, isFetching } = useGetConfigsQuery({
    region,
    scheduled: checked,
  });

  const [trigger] = useLazyDownloadConfigQuery();
  const [triggerCron, triggerCronResult] = useLazyGetCronsQuery();

  const [getConfigByUid, getConfigByUidResult] = useLazyGetConfigsByUidQuery();

  const [deleteConfig, deleteInfo] = useSuspendConfigMutation();

  const [runConfig] = useRunConfigMutation();
  const [addCron] = useAddCronMutation();
  const [updateCron] = useUpdateCronMutation();

  useEffect(() => {
    activeConfigVal &&
      dispatch(
        saveActiveConfigVal(
          activeConfigVal === "true"
            ? true
            : activeConfigVal === "false"
            ? false
            : null
        )
      );
  }, [activeConfigVal, dispatch]);

  const onChange = () => {
    let val = checked;

    addParams(searchText, !val);
    dispatch(saveActiveConfigVal(!val));
    setChecked((prev) => !prev);
  };

  useEffect(() => {
    (async () => {
      addParams(searchText, checked);
      dispatch(saveConfigTxt(searchText));
      const newSearchText = searchText.trim();

      if (!isEmpty(searchText) && data && data.length > 0) {
        const matchingRes = filterByVal(data, newSearchText);
        setFilteredPosts(matchingRes);
        if (newSearchText.length >= 36 && matchingRes.length === 0) {
          await getConfigByUid(newSearchText);
        }
      } else if (newSearchText.length >= 36) {
        const newSearchText = searchText.trim();
        await getConfigByUid(newSearchText);
      } else if (data && data.length > 0) {
        setFilteredPosts([...data]);
      } else if (data) {
        setFilteredPosts([]);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchText]);

  useEffect(() => {
    SweetAlerts(deleteInfo, `Config deleted successfully`);
  }, [deleteInfo]);

  const triggerConfig = useCallback(
    (id, type) => {
      if (checkExecAccess(userAccess, "config")) {
        setFrequency("date");
        setForecastingType("");
        setParams([{ fieldName: "", fieldValue: "" }]);
        MySwal.fire({
          title: "Trigger Config",
          html: (
            <TriggerConfigForm
              frequency={frequency}
              setFrequency={setFrequency}
              frequencyRef={frequencyRef}
              setParams={setParams}
              type={type}
              setForecastingType={setForecastingType}
              forecastingType={forecastingType}
            />
          ),
          confirmButtonText: "Trigger",
          focusConfirm: false,
          showLoaderOnConfirm: true,
          showCloseButton: true,
          preConfirm: () => {
            let reqStr = ``;
            const freq = getSelectedFrequency();
            const command = document.getElementById("command").value;
            const forecast = getSelectedForecastType();

            if (!forecast && type === "forecast") {
              return MySwal.showValidationMessage(
                `Please select a forecasting type`
              );
            }
            if (isEmpty(command)) {
              if (freq === "date_range") {
                const startDate = document.getElementById("start_date").value;
                const endDate = document.getElementById("end_date").value;
                if (isEmpty(startDate) || isEmpty(endDate)) {
                  return MySwal.showValidationMessage(
                    `Please enter a valid date`
                  );
                } else {
                  reqStr += `--start_date ${convert(
                    startDate
                  )} --end_date ${convert(endDate)} `;
                }
              } else {
                const date = document.getElementById("date").value;
                if (isEmpty(date)) {
                  return MySwal.showValidationMessage(
                    `Please enter a valid date`
                  );
                } else {
                  reqStr += `--date ${convert(date)} `;
                }
              }
              if (type === "forecast" && forecast) {
                reqStr += `--forecast_type ${forecast} `;
              }
              const params = getParmas();

              params.forEach((item) => {
                if (!isEmpty(item.fieldName) && !isEmpty(item.fieldValue)) {
                  const fieldName = item.fieldName.replace(/-/g, "_");
                  reqStr += ` --${fieldName} ${item.fieldValue} `;
                }
              });

              return runConfig({
                uuid: id,
                params: reqStr,
              }).then((response) => {
                if (response.error) {
                  Swal.showValidationMessage(
                    `Request failed: ${response.error.data.detail}`
                  );
                } else {
                  MySwal.fire(
                    "",
                    <p style={{ textAlign: "center" }}>
                      {response.data[0]}:{response.data[1]}
                    </p>,
                    "success"
                  );
                }
              });
            } else {
              return runConfig({
                uuid: id,
                params: command,
              }).then((response) => {
                if (response.error) {
                  Swal.showValidationMessage(
                    `Request failed: ${response.error.data.detail}`
                  );
                } else {
                  MySwal.fire(
                    "",
                    <p style={{ textAlign: "center" }}>
                      {" "}
                      {response.data[0]}:{response.data[1]}
                    </p>,
                    "success"
                  );
                }
              });
            }
          },
          customClass: {
            container: styles.triggerForm,
            actions: styles.triggerAction,
          },
        });
      } else {
        WarningSweetAlerts();
      }
    },
    [forecastingType, frequency, runConfig, userAccess]
  );

  const handleDelete = useCallback(
    (id) => {
      if (checkExecAccess(userAccess, "config")) {
        MySwal.fire({
          title: "Are you sure?",
          icon: "warning",
          showCloseButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Delete",
        }).then((result) => {
          if (result.isConfirmed) {
            deleteConfig(id);
          }
        });
      } else {
        WarningSweetAlerts();
      }
    },
    [deleteConfig, userAccess]
  );

  const handleDownload = useCallback(
    async (uuid) => {
      const { isSuccess, data, isError } = await trigger(uuid);
      if (isSuccess && data) {
        let anchor = document.createElement("a");
        let objectUrl = window.URL.createObjectURL(
          new Blob([data], { type: "application/pdf" })
        );
        anchor.href = objectUrl;
        anchor.download = `${uuid}.yaml`;
        anchor.click();
        window.URL.revokeObjectURL(objectUrl);
      } else if (isError) {
        WarningSweetAlerts("Some error occured", "error");
      }
    },
    [trigger]
  );

  const cronSchedular = useCallback(
    async (uuid, type) => {
      sessionStorage.setItem("uuid", uuid);
      sessionStorage.setItem("type", type);

      setForecastingTypeVal(type);
      await triggerCron(uuid);
    },
    [triggerCron]
  );

  const loadSwalFrom = useCallback(
    (
      action = "add",
      currentCronVal,
      scheduleId,
      uid,
      configFilter,
      kwargsFilter
    ) => {
      if (checkExecAccess(userAccess, "scheduler")) {
        setKwargs([{ kwargName: "", kwargValue: "" }]);
        setParams([{ kwargName: "", kwargValue: "" }]);

        const title = action === "add" ? `Add Cron` : `Edit Cron`;
        const confirmButtonText = action === "add" ? `Save` : `Update`;
        setForecastingType(null);
        let type = null;
        const values = [{ fieldName: "", fieldValue: "" }];
        const kwragsFields = [{ kwargName: "", kwargValue: "" }];

        if (action !== "add") {
          let configFiltArr = configFilter;
          let kwargsFilterArr = kwargsFilter;
          let kwargsFilterObject;

          if (kwargsFilter && isValidJSON(kwargsFilter)) {
            kwargsFilterObject = JSON.parse(kwargsFilter);
            kwargsFilterArr = Object.entries(kwargsFilterObject).map(
              ([key, value]) => ({ [key]: value })
            );
          } else {
            kwargsFilterArr = kwargsFilter;
          }

          if (typeof configFilter === "string" && configFilter !== "None") {
            configFiltArr = configFiltArr.replace(/'/g, '"');
            configFiltArr = JSON.parse(configFiltArr);
          }
          if (configFiltArr && Array.isArray(configFiltArr)) {
            console.log("i am coming here");
            values.shift();
            configFiltArr.forEach((item, index) => {
              const key = Object.keys(item)[0];
              if (key !== "forecast_type")
                values.push({
                  fieldName: key,
                  fieldValue: Object.values(item)[0],
                });
              else {
                setForecastingType(Object.values(item)[0]);
                type = Object.values(item)[0];
              }
              // Object.keys(item)[0].values[index] = Object.values(item)[0];
            });
          }
          if (
            kwargsFilterArr &&
            Array.isArray(kwargsFilterArr) &&
            kwargsFilterArr.length > 0
          ) {
            kwragsFields.shift();
            kwargsFilterArr.forEach((item, index) => {
              const key = Object.keys(item)[0];
              kwragsFields.push({
                kwargName: key,
                kwargValue: Object.values(item)[0],
              });
            });
          }
          setKwargs(kwragsFields);
          setParams(values);
        }

        MySwal.fire({
          title: title,
          html: (
            <CronForm
              setCronVal={setCronVal}
              currentCronVal={currentCronVal}
              type={sessionStorage.getItem("type")}
              setParams={setParams}
              setForecastingType={setForecastingType}
              params={values}
              forecastingType={type}
              kwrags={kwragsFields}
              setKwargs={setKwargs}
            />
          ),
          confirmButtonText,
          showCloseButton: true,
          focusConfirm: false,
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          backdrop: true,
          preConfirm: () => {
            const cronInputVal = cronValRef.current;
            const cronResult = cron(cronInputVal);
            if (!cronResult.isValid()) {
              return MySwal.showValidationMessage(
                `Please enter valid cron expression`
              );
            }

            if (cronInputVal === "* * * * *") {
              return MySwal.showValidationMessage(
                `This cron value is not allowed`
              );
            }

            // const command = document.getElementById("command").value;
            const forecast = getSelectedForecastType();

            if (!forecast && forecastingTypeVal === "forecast") {
              return MySwal.showValidationMessage(
                `Please select a forecasting type`
              );
            }

            const params = getParmas();
            const kwargs = getKwargs();

            const config_filter = [];
            const kwargs_filter = [];

            kwargs.forEach((item) => {
              if (!isEmpty(item.kwargName) && !isEmpty(item.kwargValue)) {
                kwargs_filter.push({ [item.kwargName]: item.kwargValue });
              }
            });

            params.forEach((item) => {
              if (!isEmpty(item.fieldName) && !isEmpty(item.fieldValue)) {
                config_filter.push({ [item.fieldName]: item.fieldValue });
              }
            });
            if (forecastingTypeVal === "forecast")
              config_filter.push({ forecast_type: forecast });

            const data = {
              uuid: uid,
              cron: cronInputVal,
              status: "ACTIVE",
              config_filter,
              kwargs: kwargs_filter,
            };

            if (action === "add") {
              return addCron(data).then((response) => {
                if (response.error) {
                  Swal.showValidationMessage(
                    `Request failed: ${response.error.data.detail}`
                  );
                } else {
                  MySwal.fire(
                    "",
                    <p style={{ textAlign: "center" }}>
                      Cron scheduled successfully
                    </p>,
                    "success"
                  );
                }
              });
            } else {
              data.schedule_id = scheduleId;
              return updateCron(data).then((response) => {
                if (response.error) {
                  Swal.showValidationMessage(
                    `Request failed: ${response.error.data.detail}`
                  );
                } else {
                  MySwal.fire(
                    "",
                    <p style={{ textAlign: "center" }}>
                      Cron updated successfully
                    </p>,
                    "success"
                  );
                }
              });
            }
          },
          customClass: {
            container: styles.cron_form,
          },
        });
      } else {
        WarningSweetAlerts();
      }
    },
    [addCron, forecastingTypeVal, updateCron, userAccess]
  );

  const updateCronStatus = useCallback(
    (schedule_id, uuid, cron, status, config_filter, kwargs) => {
      if (checkExecAccess(userAccess, "scheduler")) {
        let configFiltArr;
        console.log(config_filter, "configFilter");
        if (typeof config_filter === "string" && config_filter !== "None") {
          console.log(config_filter, "string");
          configFiltArr = config_filter.replace(/'/g, '"');
          configFiltArr = JSON.parse(configFiltArr);
        } else {
          configFiltArr = config_filter;
        }

        if (configFiltArr === null || configFiltArr === "None") {
          configFiltArr = [];
        }

        const jsonObject = JSON.parse(kwargs);
        const transformedArray = [];

        for (const key in jsonObject) {
          if (jsonObject.hasOwnProperty(key)) {
            const obj = {};
            obj[key] = jsonObject[key];
            transformedArray.push(obj);
          }
        }

        const data = {
          schedule_id,
          uuid,
          cron,
          status,
          config_filter: configFiltArr,
          kwargs: transformedArray,
        };
        updateCron(data);
      } else {
        WarningSweetAlerts();
      }
    },
    [updateCron, userAccess]
  );

  const cronColumns = React.useMemo(
    () => [
      {
        Header: "Cron Expression",
        accessor: "cron",
      },
      {
        Header: "Cron",
        accessor: "region_name",
        Cell: ({ cell }) =>
          cell.row.original.cron
            ? cronstrue.toString(cell.row.original.cron)
            : "",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ cell }) => (
          <>
            <div
              title="Edit"
              className={styles.action_btn_links}
              style={{
                margin: "5px",
                display: "inline-block",
                cursor: "pointer",
              }}
              onClick={() =>
                loadSwalFrom(
                  "edit",
                  cell.row.original.cron,
                  cell.row.original.schedule_id,
                  cell.row.original.uuid,
                  cell.row.original.config_filter,
                  cell.row.original.kwargs
                )
              }
            >
              <EditConfig />
            </div>

            {cell.row.original.status === "SUSPENDED" ? (
              <div
                title="Edit"
                className={styles.action_btn_links}
                style={{
                  margin: "5px",
                  display: "inline-block",
                  cursor: "pointer",
                }}
                onClick={() =>
                  updateCronStatus(
                    cell.row.original.schedule_id,
                    cell.row.original.uuid,
                    cell.row.original.cron,
                    "ACTIVE",
                    cell.row.original.config_filter,
                    cell.row.original.kwargs
                  )
                }
              >
                <Play />
              </div>
            ) : (
              <div
                title="Edit"
                className={styles.action_btn_links}
                style={{
                  margin: "5px",
                  display: "inline-block",
                  cursor: "pointer",
                }}
                onClick={() =>
                  updateCronStatus(
                    cell.row.original.schedule_id,
                    cell.row.original.uuid,
                    cell.row.original.cron,
                    "SUSPENDED",
                    cell.row.original.config_filter,
                    cell.row.original.kwargs
                  )
                }
              >
                <Pause />
              </div>
            )}
          </>
        ),
        disableSortBy: true,
      },
    ],
    [loadSwalFrom, updateCronStatus]
  );

  useEffect(() => {
    const { isFetching, data, isSuccess } = triggerCronResult;
    if (isFetching) {
      MySwal.showLoading();
    } else if (isSuccess && data) {
      MySwal.fire({
        html: (
          <Provider store={store}>
            <Row className="mt-2 no-gutters mb-2">
              <Col xs={7}>
                <PostPerPage
                  limit={postsPerPage}
                  setPostPerPage={setPostPerPage}
                  setCurrentPage={setCurrentPage}
                />
              </Col>
              <Col xs={3}></Col>
              <Col xs={2}>
                <Tablet>
                  <div
                    onClick={() =>
                      loadSwalFrom(
                        "add",
                        null,
                        null,
                        sessionStorage.getItem("uuid")
                      )
                    }
                  >
                    <TableButton>
                      <IoMdAddCircle /> <span> Add New</span>
                    </TableButton>
                  </div>
                </Tablet>

                <Mobile>
                  <div className="addNewBtn">
                    <div to={`#`} onClick={() => loadSwalFrom()}>
                      <Add />
                    </div>
                  </div>
                </Mobile>
              </Col>
            </Row>
            <ReactTable
              columns={cronColumns}
              data={data ? data : []}
              pageRows={postsPerPage}
              disableFilters={false}
            />
          </Provider>
        ),
        showCloseButton: true,
        focusConfirm: false,
        allowOutsideClick: true,
        showConfirmButton: false,
        preConfirm: () => {},
        customClass: {
          container: styles.notification_table,
          closeButton: styles.close_btn,
        },
      });
    }
  }, [
    cronColumns,
    loadSwalFrom,
    postsPerPage,
    setCurrentPage,
    triggerCronResult,
  ]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Config ID",
        accessor: "config_manager_id",
      },
      {
        Header: "UID",
        accessor: "uuid",
        maxWidth: 20,
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "",
        accessor: "class_name",
      },
      {
        Header: "Version",
        accessor: "status",
      },

      {
        Header: "Action",
        accessor: "",

        Cell: ({ cell }) => (
          <>
            <Link
              title="Edit"
              className={styles.action_btn_links}
              to={`/config-editor/${region}/${cell.row.original.uuid}?type=${cell.row.original.class_name}`}
              style={{ margin: "5px" }}
            >
              <EditConfig />
            </Link>
            <span
              title="Run Config"
              className={styles.action_btn_links}
              onClick={() =>
                triggerConfig(
                  cell.row.original.uuid,
                  cell.row.original.class_name
                )
              }
              style={{ margin: "5px", cursor: "pointer" }}
            >
              <ConfigPlay />
            </span>
            <span
              title="Cron Scheduler"
              className={styles.action_btn_spans}
              rel="noreferrer"
              style={{ margin: "5px", cursor: "pointer" }}
              onClick={() =>
                cronSchedular(
                  cell.row.original.uuid,
                  cell.row.original.class_name
                )
              }
            >
              <CronIcon />
            </span>
            <span
              title="Download"
              className={styles.action_btn_links}
              rel="noreferrer"
              style={{ margin: "5px", cursor: "pointer" }}
              onClick={() => handleDownload(cell.row.original.uuid)}
            >
              <DownloadConfig />
            </span>
            <Delete handleClick={handleDelete} id={cell.row.original.uuid} />
          </>
        ),
        disableSortBy: true,
      },
    ],
    [cronSchedular, handleDelete, handleDownload, region, triggerConfig]
  );

  return (
    <div className="mt-5">
      <Container className="no-gutter-mob">
        <PageTitle title={"Config Manager"} />
        <div className="card-box">
          <Row className={"mt-2 no-gutters mb-2 " + styles.filter_wrapper}>
            <Col lg={3} md={10} xs={9}>
              <PostPerPage
                limit={postsPerPage}
                setPostPerPage={setPostPerPage}
                setCurrentPage={setCurrentPage}
              />
            </Col>
            <Desktop>
              <Col lg={3} md={10} xs={4}>
                <Switch
                  animation="smooth"
                  style={{ fontSize: "16px" }}
                  color="success"
                  shape="round"
                  state={checked}
                  setState={setChecked}
                  onChange={onChange}
                >
                  Schedule Config
                </Switch>
              </Col>
            </Desktop>
            <Desktop>
              <Col xs={3}>
                <div
                  className={styles.searchWrapper}
                  style={{ float: "right" }}
                >
                  <SearchFilter
                    searchText={searchText}
                    setSearchText={setSearchText}
                  />
                </div>
              </Col>
            </Desktop>

            <Col xs={3}>
              <Tablet>
                <Link to={`/config-editor`}>
                  <TableButton>
                    <IoMdAddCircle /> <span> Add New</span>
                  </TableButton>
                </Link>
              </Tablet>
              <Mobile>
                <div className="addNewBtn">
                  <Link to={`/config-editor`}>
                    <Add />
                  </Link>
                </div>
              </Mobile>
            </Col>

            <Mobile>
              <Col xs={12} className="mt-4 mb-2">
                <Switch
                  animation="smooth"
                  style={{ fontSize: "16px" }}
                  color="success"
                  shape="round"
                >
                  Active Configs
                </Switch>
              </Col>
            </Mobile>

            <Mobile>
              <Col xs={12}>
                <div className="mt-3 mb-3">
                  <SearchFilter
                    searchText={searchText}
                    setSearchText={setSearchText}
                  />
                </div>
              </Col>
            </Mobile>
          </Row>
          {isFetching || getConfigByUidResult.isFetching ? (
            <Loader />
          ) : error || getConfigByUidResult.isError ? (
            <>
              <Alert variant="danger mt-4">
                Some Error occured. Please contant admin
              </Alert>
            </>
          ) : (
            <Row>
              <Col xs={12}>
                <div className="mt-3">
                  {filteredPosts.length > 0 && (
                    <ReactTable
                      columns={columns}
                      data={
                        filteredPosts.length > 0
                          ? filteredPosts
                          : filteredPosts.length === 0 &&
                            getConfigByUidResult.data &&
                            !isEmpty(searchText)
                          ? [getConfigByUidResult.data]
                          : []
                      }
                      pageRows={postsPerPage}
                      disableFilters={false}
                    />
                  )}
                  {getConfigByUidResult.data && !isEmpty(searchText) && (
                    <ReactTable
                      columns={columns}
                      data={[getConfigByUidResult.data]}
                      pageRows={postsPerPage}
                    />
                  )}

                  {filteredPosts &&
                    filteredPosts.length === 0 &&
                    (!getConfigByUidResult.data ||
                      getConfigByUidResult.data.length === 0) && (
                      <Alert variant="primary mt-4">No resuts found</Alert>
                    )}
                </div>
              </Col>
            </Row>
          )}
        </div>
      </Container>
    </div>
  );
};

export default ConfigManager;
